/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { useLocation } from 'react-router';
import { translator } from '~/presentation/components/i18n';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { iInviteData } from './interface';

import {
  Body,
  ButtonsDiv,
  TextConditions,
  BlackBox,
  Circle,
  Container,
  Content,
  Header,
  OptionsContainer,
  WebCamDisabled,
  CheckboxContainer,
} from './styles/StyledPreSettingsParticipant';
import { History } from '~/main/routes';
import { Button, Check } from '~/presentation/components/UI';
import {
  MicrophoneOn,
  CameraOn,
  MicrophoneMuted,
} from '~/presentation/base/icons/index';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

interface iStateParams {
  conference: iGuestData;
}

interface iGuestSettings {
  isMicOn: boolean;
  isCamOn: boolean;
  guestData: iGuestData | iInviteData;
}

interface ownProps {
  inviteData?: iInviteData;
}

const PreSettingsGuest: React.FC<ownProps> = ({ inviteData }): JSX.Element => {
  const [webcam, setWebcam] = useState<boolean>(false);
  const [isMic, setIsMic] = useState<boolean>(false);
  const [ruleone, setRuleOne] = useState<boolean>(false);
  const [ruletwo, setRuleTwo] = useState<boolean>(false);

  const conference = useLocation<{ conference: iGuestData }>().state
    ?.conference;

  const [guestSettings, setGuestSettings] = useState<iGuestSettings>(
    {} as iGuestSettings,
  );

  function pushTo(path: string, state?: any) {
    return state
      ? History.getHistory().push(path, guestSettings)
      : History.getHistory().push(path);
  }

  useEffect(() => {
    const initialSettings: iGuestSettings = {
      guestData: inviteData !== undefined ? inviteData : conference,
      isMicOn: false,
      isCamOn: false,
    };
    setGuestSettings(initialSettings);
  }, [conference]);

  const displayModal = () => {
    makeReduxActiveMessage().active({
      active: 'termsOfUse',
      actionOk: closeModal,
      actionCancel: closeModal,
    });
  };

  /** **************** */
  /** CONFIG CAMERA * */
  /** **************** */
  const startCamera = () => {
    setTimeout(() => {
      // Captura elemento de vídeo
      const video: HTMLVideoElement | null = document.querySelector('#webcam');

      // Verifica se o navegador pode capturar mídia
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then(function (stream) {
            window.localStream = stream;

            // Definir o elemento vídeo a carregar o capturado pela webcam
            if (!video) return;

            video.srcObject = stream;

            // As opções abaixo são necessárias para o funcionamento correto no iOS
            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
          })
          .catch(function (error) {
            // this.setState({ camStatus: false});
          });
      }
    }, 300);
  };

  const stopCamera = () => {
    console.log('camera stopped');
    try {
      // stop both video and audio
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      console.error('Não foi possível desativar a câmera.');
    }
  };

  const toggleWebcam = () => {
    if (webcam) {
      stopCamera();
    } else {
      startCamera();
    }

    setWebcam(!webcam);
    setGuestSettings({ ...guestSettings, isCamOn: Boolean(!webcam) });
  };

  const stopMic = () => {
    try {
      // stop both video and audio
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      console.error('Não foi possível desativar o microfone.');
    }
  };

  const toggleMic = () => {
    console.log('Mic toggled');
    if (isMic) {
      stopMic();
      console.log('Mic stopped');
    }

    setIsMic(!isMic);
    setGuestSettings({ ...guestSettings, isMicOn: Boolean(!isMic) });
  };

  return (
    <Container>
      <Body>
        <Header>
          <span>{translator('Antes de iniciar')}</span>
        </Header>
        <Content>
          <p>
            {translator(
              'Recomendamos que entre com o microfone e câmera ligados.',
            )}
          </p>
          <TextConditions
            target="_blank"
            href="https://codata.pb.gov.br/lgpd/termo-de-uso_assinado.pdf"
          >
            {translator('Aceite os termos de uso e de gravação para iniciar.')}
          </TextConditions>
          <BlackBox>
            {webcam ? (
              <video autoPlay id="webcam">
                <track kind="captions" />
              </video>
            ) : (
              <WebCamDisabled>{translator('Câmera desligada')}</WebCamDisabled>
            )}
          </BlackBox>
          <h3>{translator('Entrar com:')}</h3>
          <OptionsContainer>
            {isMic ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Circle onClick={() => toggleMic()}>
                  <MicrophoneOn width="26px" height="26px" />
                </Circle>
                <p
                  style={{
                    marginTop: '10px',
                    color: '#004ba5',
                  }}
                >
                  {translator('Ligado')}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Circle onClick={() => toggleMic()}>
                  <MicrophoneMuted width="26px" height="26px" />
                </Circle>
                <p
                  style={{
                    marginTop: '10px',
                    color: '#004ba5',
                  }}
                >
                  {translator('Desligado')}
                </p>
              </div>
            )}
            {webcam ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Circle onClick={toggleWebcam}>
                  <CameraOn width="26px" height="26px" />
                </Circle>
                <p
                  style={{
                    marginTop: '10px',
                    color: '#004ba5',
                  }}
                >
                  {translator('Ligado')}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Circle onClick={toggleWebcam}>
                  <VideocamOffIcon fontSize="large" htmlColor="#5C5C5C" />
                </Circle>
                <p
                  style={{
                    marginTop: '10px',
                    color: '#004ba5',
                  }}
                >
                  {translator('Desligado')}
                </p>
              </div>
            )}
          </OptionsContainer>
          <CheckboxContainer>
            <Check
              name="rule"
              checked={ruleone}
              onClick={() => setRuleOne(!ruleone)}
              label={translator('Aceito os')}
              labelLink={translator('termos de uso')}
              linkHandler={() => displayModal()}
            />
            <Check
              name="secondRule"
              checked={ruletwo}
              onClick={() => setRuleTwo(!ruletwo)}
              label={translator('Aceito que o atendimento poderá ser gravado')}
            />
          </CheckboxContainer>
          <ButtonsDiv>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                // if (
                //   [
                //     'APPTYPE_INTERCONSULTATION',
                //     'APPTYPE_CLINIC_DISCUSSION',
                //   ].includes(conference?.appointmentType.msgKey)
                // ) {
                pushTo('/conf', {
                  appointmentId: conference?.appointment.id,
                  isMicOn: guestSettings.isMicOn,
                  isCamOn: guestSettings.isCamOn,
                  guestData: {
                    appointment: {
                      id: conference?.appointment?.id,
                    },
                    guest: {
                      identification: {
                        fullName: conference?.guest?.identification?.fullName,
                        code: conference?.guest?.identification?.code,
                      },
                    },
                  },
                });
                // } else {
                //   pushTo('/guestWaitingRoom', guestSettings);
                // }
              }}
              rounded
              disabled={!(ruleone && ruletwo)}
            >
              {translator('Participar do atendimento')}
            </Button>
            <Button
              id="btn_tertiary"
              onClick={() => pushTo('/guest')}
              rounded
              size="large"
            >
              {translator('Sair do atendimento')}
            </Button>
          </ButtonsDiv>
        </Content>
      </Body>
    </Container>
  );
};

export default PreSettingsGuest;
