/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { createIntl, createIntlCache } from 'react-intl';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import translations from '~/infra/i18n/locales';
import { makeReduxCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';
import { makeReduxSetupAppointment } from '~/main/factories/usecases/appointment/SetupAppointmentFactory';
import { History } from '~/main/routes';
import {
  CopyLinkIconAlternative,
  EditIconAlternative,
  XBtnRed,
} from '~/presentation/base/icons';
import { More } from '~/presentation/components/UI/popover';
import { getLocale } from '~/utils/getLocale';
import BarStatusAppointment from '../BarStatusAppointment';
import { Button } from '../UI';
import { translator } from '../i18n';
import { AlertMessage } from '../messages/AlertMessage';
import {
  AvatarContainer,
  Body,
  BodyContent,
  BtnsContainer,
  Container,
  ContainerMain,
  EmptyStateRight,
  Footer,
  FooterContent,
  Header,
  MoreOptionsMaskBtn,
  PatientInfo,
  PatientInfoContainer,
  PopOverContainer,
  PopOverOptions,
  Text,
  TextBolded,
  TextBox,
  TextLight,
  TextoTitleFinal,
} from './styles/Styled';
import { Avatar } from '../UI/avatar';
import { makeRemoteGetAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { GetAppointmentType } from '~/domain/usecases/appointmentType/remote';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface MoreOptionsContentProps {
  editAppointment?: () => void;
  cancelAppointment?: () => void;
  copyLink?: () => void;
}

const moreOptionsContent = ({
  cancelAppointment,
  copyLink,
  editAppointment,
}: MoreOptionsContentProps) => (
  <PopOverContainer>
    {editAppointment && (
      <PopOverOptions onClick={editAppointment}>
        <EditIconAlternative />
        <span>{translator('Editar atendimento')}</span>
      </PopOverOptions>
    )}

    {copyLink && (
      <PopOverOptions onClick={copyLink}>
        <CopyLinkIconAlternative />
        <span>{translator('Copiar link')}</span>
      </PopOverOptions>
    )}

    {cancelAppointment && (
      <PopOverOptions isRed onClick={cancelAppointment}>
        <XBtnRed />
        <span>{translator('Cancelar atendimento')}</span>
      </PopOverOptions>
    )}
  </PopOverContainer>
);

interface PatientDetailsCardProps {
  appointment: GetAllAppointment.Model['results'][0] | undefined;
}

const PatientDetailsCard: React.FC<PatientDetailsCardProps> = ({
  appointment,
}) => {
  const [types, setTypes] = useState<GetAppointmentType.Model | undefined>(
    undefined,
  );
  const url = window.location.pathname;

  function formatDateToPortuguese(isoDateString: string) {
    const dateObject = new Date(isoDateString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions;

    const formattedDate = dateObject.toLocaleDateString('pt-BR', options);
    return formattedDate;
  }

  function formatTimeRange(isoDateString: string, duration: number) {
    const startTime = new Date(isoDateString);
    const endTime = new Date(startTime.getTime() + duration * 60000); // Convert duration to milliseconds

    const formatTime = (date: Date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    };

    const formattedStart = formatTime(startTime);
    const formattedEnd = formatTime(endTime);

    return `${formattedStart} - ${formattedEnd}`;
  }

  function formatToCustomDate(isoDateString: Date) {
    const dateObject = new Date(isoDateString);

    const day = dateObject.getDate();

    const monthFormatter = new Intl.DateTimeFormat('pt-BR', { month: 'long' });
    const month = monthFormatter.format(dateObject);
    const year = dateObject.getFullYear();

    return `${day} de ${month} ${year}`;
  }

  const statusPlace: Record<string, string> = {
    REMOTE: 'Teleatendimento',
    INPERSON: 'Presencial',
  };

  function calculateAge(birthDate?: string) {
    const today = new Date();
    const birthDateObj = new Date(birthDate ?? new Date());

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age -= 1;
    }

    const months = (monthDiff + 12) % 12;

    return `${age} anos e ${months} meses`;
  }

  const appointmentType = useMemo(() => {
    if (!types || !appointment) return undefined;

    return types?.find(item => item.id === appointment?.appointment?.type);
  }, [appointment, types]);

  const handleJoin = useCallback(() => {
    if (!appointment || !appointmentType) return;
    // if (
    //   ['APPTYPE_INTERCONSULTATION', 'APPTYPE_CLINIC_DISCUSSION'].includes(
    //     appointmentType.msgKey,
    //   )
    // ) {
    History.getHistory().push('/conf', {
      appointmentId: appointment?.appointment.id,
    });
    // } else {
    //   History.getHistory().push(`/appointment/waitingroom`, {
    //     appointmentId: appointment.appointment.id,
    //     consultantId: appointment.consultant?.id,
    //     professionalId: appointment.professional?.id,
    //   });
    // }
  }, [appointment, appointmentType]);

  const handleEdit = useCallback(() => {
    if (appointment)
      History.getHistory().push('/appointment/detail', {
        appointmentId: appointment.appointment.id,
        professional: appointment.professional?.id,
        consultant: appointment.consultant?.user,
        consultantId: appointment.consultant?.id,
        from: url.replaceAll('/', ''),
      });
  }, [appointment, url]);

  const handleCancel = useCallback(() => {
    if (appointment) {
      makeReduxSetupAppointment().setup({
        appointmentId: appointment.appointment.id,
      });

      makeReduxCancelAppointment().cancel({
        appointmentId: Number(appointment?.appointment?.id),
      });
    }
  }, [appointment]);

  const copyLink = useCallback(() => {
    if (appointment && appointment.appointment?.guestLink) {
      navigator.clipboard.writeText(appointment.appointment.guestLink);

      AlertMessage({
        message: intl.formatMessage({
          id: 'Link copiado para área de transferência',
        }),
        type: 'success',
      });
    }
  }, [appointment]);

  useEffect(() => {
    makeRemoteGetAppointmentType()
      .list({
        client: 'SAUDEMEET',
      })
      .then(res => {
        setTypes(res);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, []);

  return (
    <ContainerMain>
      <TextoTitleFinal>Paciente</TextoTitleFinal>
      {appointment ? (
        <Container>
          <Header>
            <PatientInfoContainer>
              <AvatarContainer>
                <Avatar
                  src={appointment?.consultant?.avatar}
                  name={`${appointment?.consultant?.firstName} ${appointment?.consultant?.lastName}`}
                  size="medium"
                />
              </AvatarContainer>

              <PatientInfo>
                <TextLight>
                  {translator('Nome')}:
                  <TextBolded>
                    {appointment?.consultant?.firstName === null
                      ? appointment?.appointment?.info?.name
                      : appointment?.consultant?.firstName}
                  </TextBolded>
                </TextLight>
                <TextLight>
                  {translator('Idade')}:
                  <TextBolded>
                    {calculateAge(
                      appointment?.consultant?.birthDate === null
                        ? appointment?.appointment?.info?.birthDate
                        : appointment?.consultant?.birthDate,
                    )}
                  </TextBolded>
                </TextLight>
                <TextLight>
                  {translator('Contato')}:
                  <TextBolded>
                    {appointment?.consultant?.phone === null
                      ? '-'
                      : appointment?.consultant?.phone}
                  </TextBolded>
                </TextLight>
                <BarStatusAppointment
                  status={appointment?.appointment?.status}
                />
              </PatientInfo>
            </PatientInfoContainer>
            {appointment?.appointment?.status !== 'CANCELED' &&
              appointment?.appointment?.status !== 'ENDED' && (
                <BtnsContainer>
                  <Button onClick={handleJoin}>
                    {translator('Iniciar Atendimento')}
                  </Button>

                  <MoreOptionsMaskBtn>
                    <More
                      rotate
                      positionY="top"
                      positionX="right"
                      content={moreOptionsContent({
                        cancelAppointment:
                          appointment?.appointment?.status === 'SCHEDULED' ||
                          appointment?.appointment?.status === 'CONFIRMED'
                            ? handleCancel
                            : undefined,
                        copyLink,
                        editAppointment: handleEdit,
                      })}
                    />
                  </MoreOptionsMaskBtn>
                </BtnsContainer>
              )}
          </Header>
          <Body>
            <BodyContent>
              <TextBolded isTitle margin="0">
                {translator('Informações da Consulta')}
              </TextBolded>
              <TextLight>
                {translator('Data')}:
                <TextBolded>
                  {formatDateToPortuguese(appointment?.appointment?.scheduled)}
                </TextBolded>
              </TextLight>
              <TextLight>
                {translator('Horário')}:
                <TextBolded>
                  {formatTimeRange(
                    appointment?.appointment?.scheduled,
                    appointment?.appointment?.duration,
                  )}
                </TextBolded>
              </TextLight>
              <TextLight>
                {translator('Convênio')}:
                <TextBolded>
                  {' '}
                  {appointment?.healthPlan?.name ?? '(nenhum)'}
                </TextBolded>
              </TextLight>
              <TextLight>
                {translator('Modalidade')}:
                <TextBolded>
                  {statusPlace[appointment?.appointment?.modality]}
                </TextBolded>
              </TextLight>
              <TextLight>
                {translator('Prioridade')}:
                <TextBolded isRed>
                  {appointment?.priority?.descr ?? '(nenhuma)'}
                </TextBolded>
              </TextLight>
              <TextLight>
                {translator('Última consulta')}:
                <TextBolded>
                  {appointment?.consultant?.lastAppointment ? (
                    formatToCustomDate(
                      new Date(appointment.consultant?.lastAppointment),
                    )
                  ) : (
                    <></>
                  )}
                </TextBolded>
              </TextLight>
            </BodyContent>
          </Body>
          <Footer>
            <FooterContent>
              <TextBolded isTitle>
                {translator('Informações da Consulta')}
              </TextBolded>
              <TextBox>
                <Text>{appointment?.appointment?.obs ?? '-'}</Text>
              </TextBox>
            </FooterContent>
          </Footer>
        </Container>
      ) : (
        <EmptyStateRight>
          <span>Não há pacientes agendados para este dia.</span>
        </EmptyStateRight>
      )}
    </ContainerMain>
  );
};
export default PatientDetailsCard;
